import { FC } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DEV_ABSOLUTE_URL, slickSliderConfig } from '@/helpers/variables';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { displayNoneImgOnError } from '@/helpers/helpers';
import styles from './SlickSlider.module.scss';

interface ISlickSliderPartner {
  books: IAdvertisingBlock;
}

const SlickSliderPartner: FC<ISlickSliderPartner> = ({ books }) => {
  return (
    <div className={styles.booksSlider}>
      <Slider {...slickSliderConfig} className={styles.booksSlider__wrap}>
        {books.map((book) => {
          const { title, litres_url, external_id } = book;

          return (
            <div key={book.id} className={styles.booksSlider__item}>
              <div className={styles.booksSlider__cover}>
                <Link prefetch={false} target="_blank`" href={litres_url} title={`Скачать книгу ${title}`}>
                  <img loading="lazy" src={`${DEV_ABSOLUTE_URL}/covers_185/${external_id}.jpg`} height="256" width="180" alt="slide" onError={displayNoneImgOnError} />
                </Link>
              </div>
              <div className={styles.booksSlider__name}>
                <Link prefetch={false} target="_blank" href={litres_url}>
                  {book.title}
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SlickSliderPartner;
